<template>
  <div>
    <img class="mx-auto mt-10 mb-6" src="@/assets/logo.png" />
    <img class="mx-auto mt-16" src="@/assets/screen-mobile-1.png" />
    <div class="mt-8 text-center">
      <div class="text-2xl">Revele seus momentos</div>
      <div class="text-sm font-normal mx-5 mt-4 mb-9 text-gray-500">
        Escolha a forma de upload e tenha em mãos seus melhores momentos
      </div>
    </div>
    <div class="ma-5 text-center">
      <!-- <button class="btn btn-outline" @click="selectInstagram()">
        <div class="inline-block align-middle">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-8 w-8"
            viewBox="0 0 24 24"
          >
            <path
              d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
            />
          </svg>
        </div>
        <div class="inline-block align-middle ml-5 text-sm">
          CARREGAR FOTOS DO INSTAGRAM
        </div>
      </button> -->

      <button class="btn btn-outline" @click="proxima()">
        <div class="inline-block align-middle">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-8 w-8"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M7 2a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H7zm3 14a1 1 0 100-2 1 1 0 000 2z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <div class="inline-block align-middle ml-5 text-sm">
          CARREGAR FOTOS DO CELULAR
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { INSTAGRAM_ID } from "@/constants/instagram";

export default {
  methods: {
    proxima() {
      this.$router.push("upload-imagens");
    },
    selectInstagram() {
      const pathRoute = `https://mobile.photonow.com.br/user/auth-instagram/`;
      const linkTo = `https://api.instagram.com/oauth/authorize?client_id=${INSTAGRAM_ID}&redirect_uri=${pathRoute}&scope=user_profile,user_media&response_type=code`;
      window.open(linkTo, "_self");
    },
  },
  mounted() {
    localStorage.removeItem("ig-code");
    localStorage.removeItem("ig-token");
    localStorage.removeItem("ig-userId");
  },
};
</script>

<style lang="scss" scoped></style>
